import React, {useEffect, useState} from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {AppBar, Box, Button, CssBaseline, Grid, Toolbar, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Sidebar from './components/Sidebar';
import AdiletStatsDisplay from './components/AdiletStatsDisplay';
import EgovqaStatsDisplay from './components/EgovqaStatsDisplay'
import PrivateRoute from "./components/PrivateRoute";

type Module = {
  displayName: string;
  apiPath: string;
};

const App: React.FC = () => {
  const { keycloak, initialized } = useKeycloak(); // Use this hook to access keycloak instance and initialized status

  // Define modules with separate display name and API path
  const modules: Module[] = [
    { displayName: 'Adilet', apiPath: 'adilet' },
    { displayName: 'EgovQA', apiPath: 'egovqa' },
  ];

  const [selectedModule, setSelectedModule] = useState<Module>(modules[0]);
  const { t, i18n } = useTranslation();

  const activeLanguage = i18n.language;

  const handleModuleSelect = (module: Module) => {
    setSelectedModule(module);
  };

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const loadStatsPage = () => {
    switch (selectedModule.apiPath) {
      case 'adilet':
        return <AdiletStatsDisplay moduleName={selectedModule.displayName} apiPath={selectedModule.apiPath} />;
      case 'egovqa':
        return <EgovqaStatsDisplay moduleName={selectedModule.displayName} apiPath={selectedModule.apiPath} />;
        // Add more cases as needed for other modules
      default:
        return null; // Or some default message/component
    }
  };

  const handleLogout = () => {
    keycloak.logout({ redirectUri: window.location.origin });
  };

  return (
        <>
          <CssBaseline />
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6">{t('Crawler Stats')}</Typography>
              <div style={{ marginLeft: 'auto' }}>
                {keycloak.authenticated ? (
                    <Button color="inherit" onClick={handleLogout}>Logout</Button>
                ) : (
                    <Button color="inherit" onClick={() => keycloak.login()}>Login</Button>
                )}
                <Button
                    onClick={() => handleLanguageChange('ru')}
                    style={{ backgroundColor: activeLanguage === 'ru' ? "rgba(0,0,0,0.1)" : 'transparent', color: 'white' }}
                >
                  RU
                </Button>
                <Button
                    onClick={() => handleLanguageChange('en')}
                    style={{ backgroundColor: activeLanguage === 'en' ? "rgba(0,0,0,0.1)" : 'transparent', color: 'white' }}
                >
                  EN
                </Button>
              </div>
            </Toolbar>
          </AppBar>
          <PrivateRoute>
            <Box sx={{ flexGrow: 1, display: 'flex', height: 'calc(100vh - 64px)' }}>
              <Grid container>
                <Grid item xs={12} sm={3} md={2} lg={2} sx={{ borderRight: 1, borderColor: 'divider' }}>
                  <Sidebar
                      modules={modules}
                      onSelectModule={handleModuleSelect}
                      selectedModule={selectedModule} // Add this line
                  />
                </Grid>
                <Grid item xs>
                  {loadStatsPage()}
                </Grid>
              </Grid>
            </Box>
          </PrivateRoute>
        </>
  );
};

export default App;
