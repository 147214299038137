import React from 'react';
import ReactDOM from 'react-dom/client';
import {ReactKeycloakProvider} from "@react-keycloak/web";
import './i18n';
import './index.css';
import App from './App';
import keycloak from "./keycloakService";

const kcInitOptions = {
    onLoad: 'check-sso',
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <ReactKeycloakProvider authClient={keycloak} initOptions={kcInitOptions}>
        <App />
    </ReactKeycloakProvider>
);