import React, {useEffect, useState} from 'react';
import Histogram from './Histogram';
import {Box, Grid, Paper, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {fetchStats} from "../api/api";
import {completeDailyCounts, completeHourlyCounts} from '../util/statUtils';
import {useKeycloak} from "@react-keycloak/web";

type AdiletStats = {
    dailyCounts: { date: string; count: number }[];
    hourlyCounts: { date: string; count: number }[];
    documentCount: number;
    documentLinkCount: number;
    taskCount: number;
    archivalTaskCount: number;
    crawledTaskCount: number;
    crawledArchivalTaskCount: number;
};

type Props = {
    moduleName: string;
    apiPath: string;
};

const AdiletStatsDisplay: React.FC<Props> = ({moduleName, apiPath}) => {
    const { keycloak } = useKeycloak();
    const {t, i18n} = useTranslation();
    const [stats, setStats] = useState<AdiletStats | null>(null);
    const [crawledPercentage, setCrawledPercentage] = useState<string>("");
    const [archivalCrawledPercentage, setArchivalCrawledPercentage] = useState<string>("");
    const [averageCrawlSpeed, setAverageCrawlSpeed] = useState<string>("");

    useEffect(() => {
        if (keycloak.authenticated) {
            const token = keycloak.token;

            const fetchData = async () => {
                try {
                    fetchStats(apiPath, token).then(data => {
                        setStats(data);
                    })
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            fetchData();
        }
    }, [keycloak]);

    useEffect(() => {
        const prepPageStats = async () => {
            if (stats) {

                const crawledPercentage = stats.taskCount > 0
                    ? (((stats.crawledTaskCount - stats.crawledArchivalTaskCount) / (stats.taskCount - stats.archivalTaskCount)) * 100).toFixed(2)
                    : "0";

                const archivalCrawledPercentage = stats.taskCount > 0
                    ? ((stats.crawledArchivalTaskCount / stats.archivalTaskCount) * 100).toFixed(2)
                    : "0";

                // Calculate the daily average crawl speed
                // Sort dailyCounts by date to find the range
                const sortedDailyCounts = stats.dailyCounts.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
                const startDate = new Date(sortedDailyCounts[0].date);
                const endDate = new Date(sortedDailyCounts[sortedDailyCounts.length - 1].date);
                const totalDays = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24) + 1; // +1 to include both start and end date

                const totalCrawled = stats.dailyCounts.reduce((acc, curr) => acc + curr.count, 0);
                const averageCrawlSpeed = (totalCrawled / totalDays).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });

                setCrawledPercentage(crawledPercentage);
                setAverageCrawlSpeed(averageCrawlSpeed);
            }
        };

        prepPageStats();
    }, [stats]);

    return (
        <Paper style={{padding: '16px', margin: '16px'}}>
            <Typography variant="h6" gutterBottom>
                {moduleName} - {t('Crawler Stats')}
            </Typography>

            {!stats
                ?
                <Typography variant="subtitle1">
                    {t('Loading')}...
                </Typography>
                :
                <>

                    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
                        <Typography variant="h5">
                            {crawledPercentage}% {t('crawled')}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
                        <Typography variant="h5">
                            {t('Avg Speed')}: {averageCrawlSpeed} {t('docs/day')}
                        </Typography>
                    </Box>


                    <Grid container spacing={2} style={{marginBottom: '16px'}}>
                        <Grid item xs={6} sm={4}>
                            <Typography variant="subtitle1">{t('URLs Discovered')}</Typography>
                            <Typography variant="body1">{stats.taskCount.toLocaleString()}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Typography variant="subtitle1">{t('Documents')}</Typography>
                            <Typography
                                variant="body1">{(stats.taskCount - stats.archivalTaskCount).toLocaleString()}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Typography variant="subtitle1">{t('Archival Versions')}</Typography>
                            <Typography variant="body1">{stats.archivalTaskCount.toLocaleString()}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{marginBottom: '16px'}}>
                        <Grid item xs={6} sm={4}>
                            <Typography variant="subtitle1">{t('URLs Crawled')}</Typography>
                            <Typography variant="body1">{stats.crawledTaskCount.toLocaleString()}</Typography>
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <Typography variant="subtitle1">{t('Documents')}</Typography>
                            <Typography
                                variant="body1">{(stats.crawledTaskCount - stats.crawledArchivalTaskCount).toLocaleString()}</Typography>
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <Typography variant="subtitle1">{t('Archival Versions')}</Typography>
                            <Typography variant="body1">{stats.crawledArchivalTaskCount.toLocaleString()}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{marginBottom: '16px'}}>
                        <Grid item xs={6} sm={4}>
                            <Typography variant="subtitle1">{t('Documents of All Versions')}</Typography>
                            <Typography variant="body1">{stats.documentCount.toLocaleString()}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Typography variant="subtitle1">{t('Links Amongst Documents')}</Typography>
                            <Typography variant="body1">{stats.documentLinkCount.toLocaleString()}</Typography>
                        </Grid>
                    </Grid>

                    <Histogram
                        data={completeDailyCounts(stats.dailyCounts)}
                        title={t("Daily Counts")}
                        isHourly={false}
                    />
                    <Histogram
                        data={completeHourlyCounts(stats.hourlyCounts)}
                        title={t("Hourly Counts")}
                        isHourly={true}
                    />
                </>
            }

        </Paper>
    );
};

export default AdiletStatsDisplay;
