
// Function to generate a complete date range array
const createDateRange = (startDate: Date, endDate: Date) => {
    let arr = [];
    for (let dt = new Date(startDate); dt <= endDate; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
};

// Function to populate missing dates with zero counts
export const completeDailyCounts = (dailyCounts: { date: string; count: number }[]) => {
    if (!dailyCounts || dailyCounts.length === 0) return [];

    const sortedDailyCounts = [...dailyCounts].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    const startDate = new Date(sortedDailyCounts[0].date);
    const endDate = new Date(sortedDailyCounts[sortedDailyCounts.length - 1].date);

    const dateRange = createDateRange(startDate, endDate);
    return dateRange.map(date => {
        const dateString = date.toLocaleDateString('en-CA'); // Format: YYYY-MM-DD
        const found = sortedDailyCounts.find(dc => dc.date === dateString);
        return {
            date: dateString,
            count: found ? found.count : 0,
        };
    });
};

// Function to generate a complete hourly range array
// Function to generate a complete hourly range array
const createHourlyRange = (startDate: Date, endDate: Date) => {
    let arr = [];
    let dt = new Date(startDate);
    while (dt <= endDate) {
        arr.push(new Date(dt));
        dt.setHours(dt.getHours() + 1);
        if (dt.getHours() === 24) {
            dt.setHours(0);
            dt.setDate(dt.getDate() + 1);
        }
    }
    return arr;
};

// Function to populate missing hours with zero counts
export const completeHourlyCounts = (hourlyCounts: { date: string; count: number }[]) => {
    if (!hourlyCounts || hourlyCounts.length === 0) return [];

    const sortedHourlyCounts = [...hourlyCounts].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    const startDate = new Date(sortedHourlyCounts[0].date);
    const endDate = new Date(sortedHourlyCounts[sortedHourlyCounts.length - 1].date);

    const hourlyRange = createHourlyRange(startDate, endDate);
    return hourlyRange.map(hour => {
        const found = sortedHourlyCounts.find(hc => {
            const hcDate = new Date(hc.date);
            return hcDate.getFullYear() === hour.getFullYear() &&
                hcDate.getMonth() === hour.getMonth() &&
                hcDate.getDate() === hour.getDate() &&
                hcDate.getHours() === hour.getHours();
        });
        return {
            date: hour.toISOString(), // Format: YYYY-MM-DDTHH:mm:ss.sssZ
            count: found ? found.count : 0,
        };
    });
};



