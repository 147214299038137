import React from 'react';
import { useKeycloak } from "@react-keycloak/web";
import {Box, Link, Typography} from "@mui/material";
import { useTranslation } from 'react-i18next';

type PrivateRouteProps = {
    children: React.ReactNode;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const { keycloak } = useKeycloak();
    const {t, i18n} = useTranslation();

    const isLoggedIn = keycloak.authenticated;

    if (isLoggedIn) {
        return <>{children}</>;
    } else {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography variant="h6" sx={{textAlign: 'center'}}>
                    {t('pleaseAuthenticate')} <Link href="#" onClick={(e) => { e.preventDefault(); keycloak.login(); }} style={{ cursor: 'pointer' }}>{t('authenticate')}</Link> {t('toAccessPage')}
                </Typography>
            </Box>
        );
    }
};

export default PrivateRoute;
