import React from 'react';
import {List, ListItem, ListItemText} from '@mui/material';

type Module = {
    displayName: string;
    apiPath: string;
};

type Props = {
    modules: Module[];
    onSelectModule: (module: Module) => void;
    selectedModule: Module;
};

const Sidebar: React.FC<Props> = ({ modules, onSelectModule, selectedModule }) => {
    return (
        <List component="nav">
            {modules.map((module) => (
                <ListItem
                    button
                    key={module.apiPath}
                    onClick={() => onSelectModule(module)}
                    selected={module.apiPath === selectedModule.apiPath} // Highlight if selected
                >
                    <ListItemText primary={module.displayName} />
                </ListItem>
            ))}
        </List>
    );
};

export default Sidebar;
