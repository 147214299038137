import React from 'react';
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {Paper, Typography} from '@mui/material';

type DataPoint = { date: string; count: number };
type Props = {
    data: DataPoint[];
    title: string;
    isHourly?: boolean; // Additional prop to determine if it's hourly data
};

const formatDate = (dateString: string, isHourly: boolean) => {
    const date = new Date(dateString);
    if (isHourly) {
        // Return the hour part only
        return date.toLocaleTimeString('en-US', { hour: '2-digit', hour12: false });
    } else {
        // Return the day part only
        return date.toLocaleDateString('en-US', { day: 'numeric', month: 'numeric' });
    }
};

const formatYAxisTick = (value: number) => {
    return new Intl.NumberFormat('en-US').format(value);
};

const Histogram: React.FC<Props> = ({ data, title, isHourly = false }) => {
    return (
        <Paper style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="date"
                        tickFormatter={(tickItem) => formatDate(tickItem, isHourly)}
                        label={{ value: 'UTC', position: 'insideBottom', offset: 0 }}
                    />
                    <YAxis tickFormatter={formatYAxisTick}/>
                    <Tooltip />
                    <Bar dataKey="count" fill="#3f51b5" />
                </BarChart>
            </ResponsiveContainer>
        </Paper>
    );
};

export default Histogram;
