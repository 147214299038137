// src/keycloakService.ts
import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
    url: 'https://auth.vetok.ai/',
    realm: 'vetok',
    clientId: 'crawler',
});

export default keycloak;
