import axios from 'axios';

// Function to fetch stats for a given module
export const fetchStats = async (moduleName: string, token: string | undefined) => {
    try {
        const response = await axios.get(`https://crawler-api.vetok.ai/v0/${moduleName}/stats`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching stats:', error);
        throw error;
    }
};
